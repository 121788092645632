import { PrismicRichText } from "@prismicio/react";
import { Button, VideoPlayer } from "@/components";

import Styles from "./Video.module.css";

/**
 * @typedef {import("@prismicio/client").Content.VideoSlice} VideoSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<VideoSlice>} VideoProps
 * @param {VideoProps}
 */
const Video = ({ slice: { primary, variation } }) => {
	const backgroundColor = primary?.background_color || false;

	// console.debug(
	// 	"[Slice] Video.jsx ::: BEGIN\n",
	// 	"props:",
	// 	"\nvariation:",
	// 	variation,
	// 	"\nprimary:",
	// 	primary
	// );
	return (
		<section className={Styles.wrapper}>
			<div className={Styles.container} data-contain="true">
				{!primary.no_text_content && (
					<div className={Styles.contentContainer}>
						{primary?.heading && (
							<h2 className={Styles.heading}>{primary?.heading}</h2>
						)}
						<PrismicRichText field={primary?.description} />
						{/* <Button link={primary?.cta_link} label={primary?.cta_label} /> */}

						{primary?.cta_label && (
							<Button link={primary?.cta_link} label={primary?.cta_label} />
						)}
					</div>
				)}

				{variation === "default" && (
					<VideoPlayer
						videoType="native"
						videoUrl={primary?.video.url}
						poster={primary?.video_thumbnail?.url}
						theme={{
							primary: "var(--red)",
							secondary: "var(--dark-grey)",
							icon: "var(--white)",
						}}
						autoPlay={false}
					/>
				)}

				{variation === "youtube" && (
					<VideoPlayer
						videoType="YouTube"
						videoURL={primary?.youtubeEmbed.html}
					/>
				)}
			</div>
			<div
				className={Styles.background}
				data-bg={backgroundColor}
				style={{ backgroundImage: `url(${primary?.background_image?.url})` }}
			></div>
		</section>
	);
};

export default Video;
